
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;

}

body {
  margin: 0;
  font-family: 'Outfit', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p{
  font-size: 1.6rem;
}

h1{ 
  font-size: 6rem;
}


.App{
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: rgba(0,0,0,.4);
  color: #fff;
  /* border: 20px solid black; */
}

.App:before{
  content: '';
  background: url("./assets/sunset.jpg") no-repeat center center/cover;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.App .search{
  text-align: center;
  padding: 1rem;
}

.App input{
  padding: 0.7rem 1.5rem;
  font-size: 1.2rem;
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
  color: #f8f8f8;
}

::placeholder{
  color: #f8f8f8;
}

.container{
  max-width: 700px;
  height: 700px;
  margin: auto;
  padding: 0 1rem;
  position: relative;
  top: 10%;
  /* border: 2px solid black; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App .top{
  width: 100%;
  margin: 1rem auto;
}

.App .description{
  position: relative;
  right: -90%;
  top: 50%;
  transform-origin: 0 0;
  transform: rotate(270deg);
  /* font-size: 4rem; */
  /* height: 12rem; */
  /* border: black solid 2px;  */
}

.App .bottom{
 /* border: white solid 2px;   */
 display: flex;
 justify-content: space-evenly;
 text-align: center;
 width: 100%;
 margin: 1rem auto ;
 padding: 1rem;
 border-radius: 12px;
 background-color: rgba(255,255,255,.2);
}

.bold{
  font-weight: 700;
}